<template>
  <div>
    <CRow>
      <WidgetsDropdown/>
      <CCol md="12">
        <!-- <CCard>
          <CCardHeader> <h2>PENYERAPAN ANGGARAN PERJADIN AKUN 524111</h2>
            <span class="float-right">
              <!-- <CDropdown
                color="warning p-0 px-2"
                placement="bottom-end"
              >
                <template #toggler-content>
                  Jenis Data
                </template>
                <CDropdownItem @click="loadData('pohon')">Pohon</CDropdownItem>
                <CDropdownItem @click="loadData('taman')">Taman</CDropdownItem>
              </CDropdown> --
                
            
            </span>
          </CCardHeader>
          <CCardBody class="p-4">
            <CRow>
              <!-- <CCol md="6">      
                <h5 class="text-center font-weight-bold">UPPKB Sintang</h5>           
                <CChartPie
                  :datasets="datasetSintang"
                  :labels="['Anggaran', 'Realisasi','Sisa Anggaran']"
                  :options = options2
                />
              </CCol> 
            </Crow>
            <!-- <gmap-map :zoom="12" :center="center" ref="mapRef" style="width: 100%; height: 600px" 
                >
              <gmap-marker
                :key="index"
                v-for="(m, index) in locationMarkers"
                :position="m.position"
                @click="openMarker(m.id)"
                :icon="{ url: m.url}"
              >
                <gmap-info-window
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === m.id"
                >
                  <div><h6 class="p-0">{{ m.name }}</h6></div>
                  <hr/>
                  <div v-html="setTable(m.description)"></div>
                </gmap-info-window>
              </gmap-marker>
            </gmap-map> 
             <CSpinner v-show="mySpinner" color="warning" style="position:absolute;left: 50%;top:50%;z-index:9000"/> 

          </CCardBody>
        </CCard> -->
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from './charts/index.js'
import { CChartPie } from '@coreui/vue-chartjs'
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
// if (localStorage.getItem("jwt") == null || localStorage.getItem("jwt") == "") {
//   router.push({ path: "/login" });
// }
export default {
  name: "Dashboard",
  components: { 
    CChartPie ,
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CChartLineSimple, 
    CChartBarSimple
  },
  computed: {
    datasetSintang () {
      return [
        {
          label: 'UPPKB Siantan',
          backgroundColor: [
            '#F24C3D',
            '#F2BE22',
            '#22A699',
          ],
          data: [24000000, 4000000, 2000000]
        }
      ]
    },
    datasetSiantan () {
      return [
        {
          label: 'UPPKB Siantan',
          backgroundColor: [
            '#F24C3D',
            '#F2BE22',
            '#22A699',
          ],
          data: [24000000, 4000000, 2000000]
        }
      ]
    }
  },
  data() {
    return {
      datasetAll: [],
      dataset:{
          label: 'UPPKB Siantan',
          backgroundColor: [
            '#F24C3D',
            '#F2BE22',
            '#22A699',
          ],
          data: [24000000, 4000000, 2000000]
      },
      options2: {
        plugins: {
          legend: {
            title: {
              display: true,
              text: 'Legend Title',
            }
          }
        },
        pieceLabel: {
            mode: 'percentage',
            fontColor: '#fff',
            precision: 1
        }
      },
      mySpinner : false,
      drainase : 0,
      jalan : 0,
      sarana : 0,
      prasarana : 0,
      pohon : 0,
      taman : 0,
      pengaduan : 0,
      informasipohon : 0,
      options: {
        zoom: 12,
        center: {
          lat: 39.9995601,
          lng: -75.1395161
        },
        mapTypeId: "roadmap",
                
      },
      center: {
        lat: -0.060538, 
        lng: 109.353780,
      },
      openedMarkerID: null,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      window_open: false,
      currentMidx: null,  
      selected: "Month",
      tableFields: [
        { key: "avatar", label: "", _classes: "text-center" },
        { key: "user" },
        { key: "country", _classes: "text-center" },
        { key: "usage", label:"Progress" },
        { key: "payment", label: "Icon", _classes: "text-center" },
        { key: "activity" },
      ],
      locations:'',
    };
  },
  mounted() {
    this.loadData('pohon');
    
  },

  // computed: {
  //   google: gmapApi,
  //   markers() {
  //     return this.locations.map(({ label, location: { lat, lon }, name, prev }) => ({
  //       label: {
  //         text: label,
  //         color: '#333',
  //         fontWeight: 'bold',
  //         fontSize: '30px'
  //       },
  //       position: {
  //         lat,
  //         lng: lon
  //       },
  //       name,
  //       prev,
  //       icon: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#28a745" width="44" height="44" viewBox="0 0 24 24"><path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z"/></svg>'
  //     }));
  //   }
  // },
  methods: {
    setTable(desc){
      var descArray = desc.split("#");
      var newDesc="<table border=0 width='320px' cellpadding='2'>";  
      for (let index = 0; index < descArray.length; index++) {
        const element = descArray[index];
        newDesc += "<tr>";
        var isiDesc = element.toString().split(":");
        for (let i2 = 0; i2 < isiDesc.length; i2++) {
          const element2 = isiDesc[i2];
          if(i2==0){
            newDesc +="<td>"+element2+"</td>";
          }
          else{
            newDesc +="<td> : "+element2+"</td>";
          }
          
        }   
        newDesc += "</tr>"; 
      }
      newDesc += "</table>";
      return newDesc;
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    addLocationMarker(id, nama, description,latt, long, url) {
      // if (this.existingPlace) {
      // const marker = {
      //   lat: this.existingPlace.geometry.location.lat(),
      //   lng: this.existingPlace.geometry.location.lng()
      // };

      const marker = {
        lat: +latt,
        lng: +long,
      };
      this.locationMarkers.push({ position: marker, id: id, name: nama, description:description, url:url });
      this.locPlaces.push(this.existingPlace);
      // this.center = marker;
      // this.existingPlace = null;
      // }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    loadData: function (jenis) {
      this.mySpinner = true;
      this.locationMarkers = [];
      this.locPlaces = []; 
      // axios
      //   .get(
      //     "https://sipanja-dishub.kalbarprov.go.id/api/public/index.php/rambumarkers/"+jenis+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu"
      //   )
      //   .then((response) => {
      //     this.locPlaces = response.data;
      //     Object.values(this.locPlaces).forEach((item) =>
      //       this.addLocationMarker(
      //         item.id,
      //         item.name,
      //         item.description,
      //         item.lat,
      //         item.lng,
      //         item.url
      //       )
      //     );
      //     this.mySpinner = false;
          

      //     // for (property in Object.values(this.locPlaces)){
      //     //   // this.form.examiner_id.push(this.locPlaces[property].id);
      //     //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
      //     // }
      //   });
   },
  },
};
</script>
<style scoped>

  #map {
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
  
  .list-inline-item {
    cursor: pointer;
  }
  
  </style>
